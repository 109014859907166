<template>
    <div>
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <div class="d-flex align-center pt-3 flex-md-wrap col-md-11 col-lg-11">
                    <v-text-field
                        :placeholder="$t('message.no')"
                        :value="searchTerm"
                        class="filter-width-185 force-text-left"
                        clearable
                        dense
                        hide-details="auto"
                        append-icon="search"
                        solo
                        @change="searchTerm = $event"
                    />
                    <v-select
                        :items="years"
                        :loading="loading.filter.years"
                        :placeholder="$t('message.year')"
                        class="filter-width-185 ml-2"
                        autocomplete="off"
                        dense
                        hide-details="auto"
                        item-text="Year.text"
                        item-value="Year.id"
                        required
                        solo
                        v-model="filterOptions.year"
                        @change="loadPayments()"
                    ></v-select>
                    <v-select
                        :items="months"
                        :loading="loading.filter.months"
                        :placeholder="$t('message.month')"
                        autocomplete="off"
                        class="filter-width-185 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Month.text"
                        item-value="Month.id"
                        required
                        solo
                        v-model="filterOptions.month"
                        @change="loadPayments()"
                    >
                        <template v-slot:selection="{item}">
                            {{ item.Month.code }}
                        </template>
                    </v-select>
                    <v-autocomplete
                        :items="paymentBuyers"
                        :loading="loading.filter.buyers"
                        :placeholder="$t('message.buyer')"
                        autocomplete="off"
                        class="filter-width-185 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Customer.id"
                        item-text="Customer.title"
                        solo
                        v-model="filterOptions.buyer"
                        @change="loadPayments()"
                    >
                    </v-autocomplete>
                    <v-autocomplete
                        :items="paymentSuppliers"
                        :loading="loading.filter.suppliers"
                        :placeholder="$t('message.supplier')"
                        autocomplete="off"
                        class="filter-width-185 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Supplier.id"
                        item-text="Supplier.title"
                        solo
                        v-model="filterOptions.supplier"
                        @change="loadPayments()"
                    >
                    </v-autocomplete>
                    <div class="col-12 height-10px pa-0" v-if="$vuetify.breakpoint.mdAndDown"></div>
                    <v-select
                        :class="'filter-width-185' + ($vuetify.breakpoint.lgAndUp ? ' ml-2' : '')"
                        :items="documentTypes"
                        :placeholder="$t('message.type')"
                        autocomplete="off"
                        clearable
                        dense
                        hide-details="auto"
                        solo
                        v-model="filterOptions.salesType"
                        @change="loadPayments()"
                    ></v-select>
                    <v-select
                        :items="paymentStatuses"
                        :placeholder="$t('message.paymentStatus')"
                        autocomplete="off"
                        class="filter-width-185 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        solo
                        v-model="filterOptions.paymentStatus"
                        @change="loadPayments()"
                    ></v-select>
                    <v-btn
                        :loading="loading.clear"
                        class="ml-2 px-1 mr-2"
                        @click="resetFilters"
                    >{{ $t('message.clear') }}</v-btn>
                </div>
                <v-spacer></v-spacer>
                <ExportTablePayment
                    :export-conditions="exportConditions"
                    :export-data="Payments"
                    :export-fields="headers"
                    class="ml-3"
                />
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.payments"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular color="primary" indeterminate size="40" width="6"/>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: [10,20,50,-1],
                showCurrentPage: true,
                showFirstLastPage: true
            }"
            :headers="headers"
            :items="Payments"
            :search="searchTerm"
            :options.sync="tableOptions"
            calculate-widths
            class="mt-3 appic-table-light specification-table"
            dense
            id="paymentTable"
            item-key="Sale.document_id"
            sort-by="Sale.salesdate"
            sort-desc
        >
            <template v-slot:item.Sale.title="{item}">
                <span class="font-weight-bold text-no-wrap"  v-if="[1,2,3,5,6].includes(item.Sale.salestype_id)">
                    {{ item.Sale.Contract.version != null ? [item.Sale.Contract.title, item.Sale.Contract.revision_no , item.Sale.Contract.partial_no].filter(Boolean).join('-') : item.Sale.Contract.title }}
                </span>
                <span class="font-weight-bold text-no-wrap" v-else>{{ item.Sale.title }}</span>
            </template>
            <template v-slot:item.Sale.salesdate="{item}">
                {{ formatDate(item.Sale.salesdate) }}
            </template>
            <template v-slot:item.Sale.Office.title="{item}">
                {{ item.Sale.Office.title }}
            </template>
            <template v-slot:item.Sale.office_id="{item}">
                {{ findOffice(item.Sale.office_id) }}
            </template>
            <template v-slot:item.Sale.Customer.title="{item}">
                {{ item.Sale.Customer.title }}
            </template>
            <template v-slot:item.Sale.Supplier.title="{item}">
                {{ item.Sale.Supplier.title }}
            </template>
            <template v-slot:item.Sale.Invoice.title="{item}">
                <span class="font-weight-bold text-no-wrap">{{ item.Sale.Invoice.title }}</span>
            </template>
            <template v-slot:item.Sale.PaymentStatement.term="{item}">
                <span v-if="[1,2,5,99].includes(item.Sale.salestype_id)">{{ item.Sale.PaymentStatement.term != null ? item.Sale.PaymentStatement.term : '' }}</span>
                <span v-else>{{ $t('message.na') }}</span>
            </template>
            <template v-slot:item.Sale.PoPaymentStatement.term="{item}">
                <span v-if="[1,3,5,6,100].includes(item.Sale.salestype_id)">{{ item.Sale.PoPaymentStatement.term != null ? item.Sale.PoPaymentStatement.term : '' }}</span>
                <span v-else>{{ $t('message.na') }}</span>
            </template>
            <template v-slot:item.Sale.etddate="{item}">
                <span v-if="[1,2,3,5,6,100].includes(item.Sale.salestype_id)">{{ formatDate(item.Sale.etddate) }}</span>
                <span v-else>{{ $t('message.na') }}</span>
            </template>
            <template v-slot:item.Sale.etadate="{item}">
                <span v-if="[1,2,3,5,6,100].includes(item.Sale.salestype_id)">{{ formatDate(item.Sale.etadate) }}</span>
                <span v-else>{{ $t('message.na') }}</span>
            </template>
            <template v-slot:item.Sale.Payment.amount="{item}">
                <div class="text-no-wrap" v-if="[5].includes(item.Sale.salestype_id)">{{ (item.Sale.currency != null ? item.Sale.currency + " " : '') + formatThisNumber(item.Sale.Payment.amount,findCurrencyPrecision(item.Sale.currency_id)) }}</div>
                <div class="text-no-wrap" v-else-if="[1,2,5,99].includes(item.Sale.salestype_id) && item.Sale.Payment.currency != null">{{ item.Sale.Payment.currency + formatThisNumber(item.Sale.Payment.amount,findCurrencyPrecision(item.Sale.Payment.currency_id)) }}</div>
                <div class="text-no-wrap" v-else-if="[1,2,5,99].includes(item.Sale.salestype_id) && item.Sale.Payment.currency == null">{{ item.Sale.currency + formatThisNumber(item.Sale.Payment.amount,findCurrencyPrecision(item.Sale.currency_id)) }}</div>
                <div v-else>{{ $t('message.na') }}</div>
            </template>
            <template v-slot:item.Sale.Payment.paid="{item}">
                <div class="text-no-wrap" v-if="[1,2,5,99].includes(item.Sale.salestype_id) && item.Sale.Payment.currency != null">{{ item.Sale.Payment.currency  + formatThisNumber(item.Sale.Payment.paid,findCurrencyPrecision(item.Sale.Payment.currency_id)) }}</div>
                <div class="text-no-wrap" v-else-if="[1,2,5,99].includes(item.Sale.salestype_id) && item.Sale.Payment.currency == null">{{ item.Sale.currency + formatThisNumber(item.Sale.Payment.paid,findCurrencyPrecision(item.Sale.currency_id)) }}</div>
                <div v-else>{{ $t('message.na') }}</div>
            </template>
            <template v-slot:item.Sale.Payment.unpaid="{item}">
                <div :class="'text-no-wrap' + (item.Sale.Payment.unpaid > 0 ? ' red--text darken-1' : '')" v-if="[1,2,5,99].includes(item.Sale.salestype_id) && item.Sale.Payment.currency != null">{{ item.Sale.Payment.currency + formatThisNumber(item.Sale.Payment.unpaid,findCurrencyPrecision(item.Sale.Payment.currency_id)) }}</div>
                <div :class="'text-no-wrap' + (item.Sale.Payment.unpaid > 0 ? ' red--text darken-1' : '')" v-else-if="[1,2,5,99].includes(item.Sale.salestype_id) && item.Sale.Payment.currency == null">{{ item.Sale.currency + formatThisNumber(item.Sale.Payment.unpaid,findCurrencyPrecision(item.Sale.currency_id)) }}</div>
                <div v-else>{{ $t('message.na') }}</div>
            </template>
            <template v-slot:item.Sale.Payment.latestpaymentdate="{item}">
                <span v-if="[1,2,5,99].includes(item.Sale.salestype_id)">{{ formatDate(item.Sale.Payment.latestpaymentdate) }}</span>
                <span v-else>{{ $t('message.na') }}</span>
            </template>
            <template v-slot:item.Sale.PoPayment.amount="{item}">
                <div class="text-no-wrap" v-if="[1,3,5,6,100].includes(item.Sale.salestype_id)">{{ (item.Sale.PoPayment.currency != null ? item.Sale.PoPayment.currency + " " : (item.Sale.Payment.currency != null ? item.Sale.Payment.currency + " " : item.Sale.currency + " ")) + formatThisNumber(item.Sale.PoPayment.amount,findCurrencyPrecision(item.Sale.PoPayment.currency_id != null ? item.Sale.PoPayment.currency_id : item.Sale.Payment.currency_id)) }}</div>
                <div v-else>{{ $t('message.na') }}</div>
            </template>
            <template v-slot:item.Sale.PoPayment.paid="{item}">
                <div class="text-no-wrap" v-if="[1,3,5,6,100].includes(item.Sale.salestype_id)">{{ (item.Sale.PoPayment.currency != null ? item.Sale.PoPayment.currency + " " : (item.Sale.Payment.currency != null ? item.Sale.Payment.currency + " " : item.Sale.currency + " ")) + formatThisNumber(item.Sale.PoPayment.paid,findCurrencyPrecision(item.Sale.PoPayment.currency_id != null ? item.Sale.PoPayment.currency_id : item.Sale.Payment.currency_id)) }}</div>
                <div v-else>{{ $t('message.na') }}</div>
            </template>
            <template v-slot:item.Sale.PoPayment.unpaid="{item}">
                <div :class="'text-no-wrap' + (item.Sale.PoPayment.unpaid > 0 ? ' red--text darken-1' : '')" v-if="[1,3,5,6,100].includes(item.Sale.salestype_id)">{{ (item.Sale.PoPayment.currency != null ? item.Sale.PoPayment.currency + " " : (item.Sale.Payment.currency != null ? item.Sale.Payment.currency + " " :  item.Sale.currency + " ")) + formatThisNumber(item.Sale.PoPayment.unpaid,findCurrencyPrecision(item.Sale.PoPayment.currency_id != null ? item.Sale.PoPayment.currency_id : item.Sale.Payment.currency_id)) }}</div>
                <div v-else>{{ $t('message.na') }}</div>
            </template>
            <template v-slot:item.Sale.PoPayment.latestpaymentdate="{item}">
                <span v-if="[1,3,5,6,100].includes(item.Sale.salestype_id)">{{ formatDate(item.Sale.PoPayment.latestpaymentdate) }}</span>
                <span v-else>{{ $t('message.na') }}</span>
            </template>
            <template v-slot:item.Sale.document_id="{ item }">
                <v-menu>
                    <template v-slot:activator="{ on: menu }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                    <v-icon>more_vert</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('message.moreActions') }}</span>
                        </v-tooltip>
                    </template>
                    <v-list dense>
                        <v-list-item class="font-sm" @click="updatePayments(item.Sale.document_id,item.Sale.salestype_id)"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updatePayments') }}</v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>
        <SimpleAlert
            :alert_message.sync="dialogs.error_message"
            :dialog.sync="dialogs.error"
            @dialog-closed="dialogClosed"
        >
        </SimpleAlert>
    </div>
</template>

<script>
    import {formatDate, numberFormat} from "Helpers/helpers";
    // import SimpleAlert from "Components/Appic/SimpleAlert";
    import {mapGetters, mapActions} from "vuex";
    import {mapFields} from "vuex-map-fields";

    const ExportTablePayment = () => import("Components/Appic/ExportTablePayment");
    const SimpleAlert = () => import("Components/Appic/SimpleAlert");

    export default {
        name: "PaymentsListing",
        components: {ExportTablePayment, SimpleAlert},
        data() {
            return {
                dialogs: {
                    error:  false,
                    error_message: ""
                },
                defaultFilterOptions: {
                    buyer: null,
                    office: null,
                    paymentStatus: null,
                    supplier: null,
                    month: null,
                    year: null,
                },
                documentTypes: [
                    {value: 1, text: this.$t('message.directSales')},
                    {value: 2, text: this.$t('message.commSales')},
                    {value: 5, text: this.$t('message.servicedSales')},
                    {value: 3, text: this.$t('message.warehousePO')},
                    {value: 99, text: this.$t('message.whSales')},
                    {value: 100, text: this.$t('message.exportPurchaseRequests')}
                ],
                filterOptions: {
                    buyer: null,
                    documentType: null,
                    office: null,
                    paymentStatus: null,
                    supplier: null,
                    month: null,
                    year: null,
                    salesType: null
                },
                hideDefaultFooter: true,
                loading: {
                    filter: {
                        buyers: false,
                        months: false,
                        offices: false,
                        payments: false,
                        suppliers: false,
                        years: false,
                    },
                    filterResults: false,
                    fetch: false,
                    payments: false,
                    reset: false
                },
                Payments: [],
                paymentStatuses: [
                    {value: 1, text: this.$t('message.buyerPending')},
                    {value: 2, text: this.$t('message.supplierPending')},
                    {value: 3, text: this.$t('message.buyerFullyPaid')},
                    {value: 4, text: this.$t('message.supplierFullyPaid')}
                ],
                searchField: null,
                searchTerm: null,
                tableOptions: {
                    page: 1
                },
                totalPayments: 0,
                // years: [
                //     {Year: {text: "2021", id: 2021}},
                //     {Year: {text: "2020", id: 2020}},
                //     {Year: {text: "2019", id: 2019}},
                //     {Year: {text: "2018", id: 2018}}
                // ]
            }
        },
        computed: {
            ...mapFields('payment',{
                exportConditions: 'exportConditions',
                paymentBuyers: 'current.paymentBuyers',
                paymentSuppliers: 'current.paymentSuppliers'
            }),
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
            ...mapGetters('currency',{
                allCurrencies: 'allCurrencies'
            }),
            ...mapGetters([
                'currencies',
                'months',
                'offices',
                'salesTypes'
            ]),
            headers () {
                let headers = [
                    {
                        id: 0,
                        text: this.$t('message.actions'),
                        value: 'Sale.document_id',
                        sortable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct'
                    },
                    {
                        id: 1,
                        text: this.$t('message.date'),
                        value: 'Sale.salesdate',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 2,
                        text: this.$t('message.contractPoSoEpr'),
                        value: 'Sale.title',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 3,
                        text: this.$t('message.invoice'),
                        value: 'Sale.Invoice.title',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 4,
                        text: this.$t('message.office'),
                        value: 'Sale.office_id',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 5,
                        text: this.$t('message.sales'),
                        value: 'Sale.SalesContact.code',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 6,
                        text: this.$t('message.buyer'),
                        value: 'Sale.Customer.title',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 7,
                        text: this.$t('message.supplier'),
                        value: 'Sale.Supplier.title',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 8,
                        text: this.$t('message.etd'),
                        value: 'Sale.etddate',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 9,
                        text: this.$t('message.eta'),
                        value: 'Sale.etadate',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 10,
                        text: this.$t('message.paymentTermBuyer'),
                        value: 'Sale.PaymentStatement.term',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 11,
                        text: this.$t('message.amountDueFromBuyer'),
                        value: 'Sale.Payment.amount',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 12,
                        text: this.$t('message.paidFromBuyer'),
                        value: 'Sale.Payment.paid',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 13,
                        text: this.$t('message.unpaidDueFromBuyer'),
                        value: 'Sale.Payment.unpaid',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 14,
                        text: this.$t('message.latestPaymentFromBuyer'),
                        value: 'Sale.Payment.latestpaymentdate',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 15,
                        text: this.$t('message.paymentTermSupplier'),
                        value: 'Sale.PoPaymentStatement.term',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 16,
                        text: this.$t('message.amountDueSupplier'),
                        value: 'Sale.PoPayment.amount',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 17,
                        text: this.$t('message.paidFromSupplier'),
                        value: 'Sale.PoPayment.paid',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 18,
                        text: this.$t('message.unpaidDueToSupplier'),
                        value: 'Sale.PoPayment.unpaid',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 19,
                        text: this.$t('message.latestPaymentToSupplier'),
                        value: 'Sale.PoPayment.latestpaymentdate',
                        sortable: true,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    }
                ]
                return headers
            },
            searchFields() {
                return this.headers.filter((header)=>header.searchable === true)
            },
            years() {
                let array = []
                let currentYear = new Date().getFullYear()
                let startYear = currentYear - 5
                while(currentYear > startYear){
                    array.push({Year: {text: currentYear.toString(), id: currentYear}})
                    currentYear = currentYear - 1
                }
                return array
            }
        },
        methods: {
            ...mapActions('currency',{
                getAllCurrencies: 'getAllCurrencies'
            }),
            ...mapActions('payment',{
                cancelPaymentById: 'cancelPaymentById',
                getAllPayments: 'getAllPayments',
                getAllPaymentBuyers: 'getAllPaymentBuyers',
                getAllPaymentSuppliers: 'getAllPaymentSuppliers',
                searchAllPayments: 'searchAllPayments',
            }),
            async cancelPayment (val, title) {

            },
            currency(currency_id) {
                return this.allCurrencies.find((currency) => currency.Currency.id == currency_id)?.Currency?.code
            },
            dialogClosed () {
                this.dialogs.error = false;
                this.dialogs.error_message = ''
            },
            filterResults () {
                this.loading.filterResults = true
                let _this = this
                setTimeout(function(){
                    _this.loading.filterResults = false
                },700)
            },
            findOffice (val) {
                let office = this.offices.find(o => o.Office.id == val)
                if(office){
                    return office.Office.code
                }
                return ''
            },
            findCurrencyPrecision (val) {
                let currency = this.allCurrencies.find(c => c.Currency.id == val)
                return currency?.Currency?.formatPrecision
            },
            formatDate,
            formatThisNumber(value,format){
                return numberFormat(value,format)
            },
            loadPayments(){
                this.loading.payments = true
                this.loading.fetch = true
                let payload = {
                    filterOptions: this.filterOptions,
                }
                this.getAllPayments(payload)
                    .then((tableData) => {
                        this.Payments = tableData.rows
                        this.totalPayments = tableData.totalRows
                    })
                    .catch(() => {
                        this.loading.payments = false
                        this.loading.fetch = false;
                    })
                    .finally(() => {
                        this.loading.payments = false
                        this.loading.fetch = false
                        this.tableOptions.page = 1
                    })
            },
            resetFilters () {
                this.loading.reset = true
                this.filterOptions = _.cloneDeep(this.defaultFilterOptions)
                let d = new Date()
                this.filterOptions.year = d.getFullYear()
                this.loadPayments()
                this.loading.reset = false
            },
            updatePayments (id,salesType){
                let type = salesType == 99 ? 'localsales' : (salesType == 100 ? 'eprs' : 'contracts')
                let tab = window.open('/v1/payments/update/' + type + '/' + id,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            }
        },
        created() {
            if(this.allCurrencies.length == 0) this.getAllCurrencies()

            let d = new Date()
            this.filterOptions.year = d.getFullYear()

            if(this.paymentBuyers.length == 0) {
                this.loading.filter.buyers = true
                this.getAllPaymentBuyers()
                    .then(() => {
                        this.loading.filter.buyers = false
                    })
                    .catch(() => {
                        this.loading.filter.buyers = false
                    })
            }
            if(this.paymentSuppliers.length == 0) {
                this.loading.filter.suppliers = true
                this.getAllPaymentSuppliers()
                    .then(() => {
                        this.loading.filter.suppliers = false
                    })
                    .catch(() => {
                        this.loading.filter.suppliers = false
                    })
            }
            this.loadPayments()
        }
    }
</script>

<style>
.filter-width-180 {
    width: 180px !important;
    max-width: 180px !important;
}
.filter-width-185 {
    width: 185px !important;
    max-width: 185px !important;
}
.border-top-1 {
    border-top: 1px solid lightgray !important;
}
.col-0-75 {
    flex: 0 0 6.25%;
    max-width: 6.25%;
}
.col-1-5 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
}
.col-3-5 {
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
}
.v-dialog {
    min-width: 200px !important;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.font-xs {
    font-size: 0.60rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table-header th {
    white-space: nowrap;
}
.height-10px {
    height: 10px;
}
</style>